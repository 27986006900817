
export const logoUrls = [
  "logo_ARTDYNE.png",
  "logo_Embedded_blue.png",
  "logo_Gallery_SCENA.png",
  "logo_megumiogita.png",
  "logo_Roid_works_gallery.png",
  "logo_whytnottokyo.png",
  "logo_yukikomizutani.png",
  "logo_tomohiko_yoshino.png",
  "logo_SHIKISAISYA.png",
  "logo_harmas.png",
  "logo_Initial_Fashion.png",
  "logo_kawata_gallery.png",
  "logo_YuHarada.png",
  "logo_ueda.png",
  "logo_raart_gallery.png",
  "logo_taimei_gallery.png",
  "logo_empathy_gallery.png",
  "logo_sh.jpg",
]
